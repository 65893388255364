import { byInclusion$ } from '@gmini/common/lib/classifier-editor/DependencyTree/Filters/InclusionFilter/FilterModel'
import { createInclusionModel } from '@gmini/common/lib/classifier-editor/DependencyTree/Inclusion'
import * as smApi from '@gmini/sm-api-sdk'

import { classifierService } from '../../../services/classifierService'
import { notificationService } from '../../../services/notificationService'
import { currentUserClassifier$ } from '../../CurrentUserClassifier'

import { dependencyTreeModel } from './current/dependencyTreeModel'
import { dependencyExpandModel } from './dependencyExpandModel'

const notification = notificationService.message.filter({
  fn: smApi.NotificationEvent.is,
})

export const {
  fetchAllInclusion,
  loadOnceNodeInclusion,
  inclusionStore$,
  currentInclusionMap$,
  filteredFlatTree$,
} = createInclusionModel({
  flatTree$: dependencyTreeModel.flatTree$,
  expanded$: dependencyExpandModel.expanded$,
  currentEntity$: currentUserClassifier$,
  nodes$: classifierService.nodes$,
  notification,
  inclusionFilterStatus$: byInclusion$,
})
