import { combine, forward, merge, sample } from 'effector'

import { prop } from 'ramda'

import { isNotEmpty } from '@gmini/utils'

import {
  adapter,
  apiToNodeTypeMap,
  isGroupType,
} from '@gmini/common/lib/classifier-service/adapters'

import {
  isApiFlatNode,
  resetChecked,
  updateChecked,
  ViewerCheckedMap,
} from '@gmini/common/lib/classifier-editor'

import { createDependencyModelUpdatedEvent } from '@gmini/common/lib/classifier-editor/dependencyModelUpdatedEvent'

import * as smApi from '@gmini/sm-api-sdk'

import { resetShowMode } from '@gmini/common/lib/forge-viewer/model/selectModel'

import { setExtensionsToLoad } from '@gmini/common/lib/forge-viewer/extensions'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { notificationService } from '../../../../services/notificationService'
import { estimationService } from '../../../../services/estimationService'

import { currentUserClassifier$ } from '../../../CurrentUserClassifier'

import { classifierService } from '../../../../services/classifierService'

import { classifier$, estimation$, estimationId$ } from './store'

import { removeAllRules } from './removeAllCalculations'
import { dependencyCheckedModel, editorCheckedModel } from './checkedModel'
import { dependencyTreeModel } from './dependencyTreeModel'

forward({
  from: estimationId$.updates.filterMap(id => (id ? { id } : undefined)),
  to: api.Estimation.getMostRecent.defaultContext.submit,
})

forward({
  from: estimation$.updates.filter({ fn: isNotEmpty }).map(estimation => ({
    id: estimation.classifierId,
    version: estimation.classifierVersion,
  })),
  to: smApi.UserClassifier.getMostRecent.defaultContext.submit,
})

const smApiNotification = notificationService.message.filter({
  fn: smApi.NotificationEvent.is,
})

const updated = notificationService.message
  .filter({ fn: api.NotificationEvent.Update.is })
  .map(prop('payload'))

const smUpdated = notificationService.message
  .filter({ fn: smApi.NotificationEvent.Update.is })
  .map(prop('payload'))

forward({
  from: sample(
    currentUserClassifier$,
    smUpdated.filter({ fn: smApi.BaseClassifier.is }),
    (currentUserClassifier, sourceClassifier) => ({
      currentUserClassifier,
      sourceClassifier,
    }),
  ).filterMap(({ currentUserClassifier, sourceClassifier }) => {
    if (
      currentUserClassifier?.sourceClassifiers.some(
        item =>
          item.type === 'BaseClassifierNode' && item.id === sourceClassifier.id,
      )
    ) {
      return currentUserClassifier
    }
  }),
  to: smApi.getSourceClassifiersVersions.submit,
})

// Для работы с чекнутыми элементами во вьювере
// TODO После рефакторинга логики fromEditorToViewer выпилить
dependencyCheckedModel.checked$.updates.watch(checkedMap => {
  if (Object.keys(checkedMap).length > 0) {
    const checkedMap = combine(
      {
        tree: dependencyTreeModel.flatTree$,
        checked: dependencyCheckedModel.checked$,
      },
      ({ checked, tree }) => {
        const keys = Object.keys(checked)

        return tree
          .filter(isApiFlatNode)
          .filter(item =>
            keys.some(k => item.path.join(':') === k && checked[k]),
          )
          .reduce(
            (acc, { ref }) =>
              ref
                ? {
                    ...acc,
                    [ref.type + ref.id]: ref,
                  }
                : acc,
            {} as ViewerCheckedMap,
          )
      },
    )
    updateChecked({
      path: 'Source',
      checkedMap: checkedMap.getState(),
    })
  } else {
    resetChecked('Source')
    resetShowMode()
  }
})

export const updateEstimation = sample(
  estimationId$,
  merge([
    api.Estimation.getMostRecent.doneData,
    updated.filter({ fn: api.Estimation.is }),
  ]),
  (currentEstimationId, estimation) =>
    estimation.id === currentEstimationId ? estimation : null,
).filter({ fn: isNotEmpty })

estimation$
  .on(updateEstimation, (_, estimation) => estimation)
  .reset(estimationService.Gate.close)

export const updateClassifier = sample(
  estimation$,
  merge([
    smApi.UserClassifier.getMostRecent.doneData,
    smUpdated.filter({ fn: smApi.UserClassifier.is }),
  ]),
  (currentEstimation, classifier) =>
    currentEstimation &&
    currentEstimation.classifierId === classifier.id &&
    currentEstimation.classifierVersion === classifier.version
      ? classifier
      : null,
)
  .filter({ fn: isNotEmpty })
  .map(adapter)

classifier$
  .on(updateClassifier, (_, classifier) => classifier)
  .reset(estimationService.Gate.close)

estimationService.estimation.currentEstimation$.updates.watch(estimation => {
  if (estimation) {
    api.Estimation.fetchStatus.defaultContext.submit({
      estimationId: estimation.id,
      estimationVersion: estimation.version,
    })
  }
})

const prevDeleteGroup = smApi.UserClassifierGroup.remove.use.getCurrent()

// Переопределяем вызов эндпоинта для удаления calculation перед удалением группы (это важно, иначе сломается бэкенд)
smApi.UserClassifierGroup.remove.use(async params => {
  // TODO getState()
  const calculations =
    estimationService.estimationCalculation.calculation$.getState()
  const currentEstimation =
    estimationService.estimation.currentEstimation$.getState()

  const calculation = Object.values(calculations).find(
    c => c && c.groupId === params.id,
  )
  if (calculation && currentEstimation) {
    await api.EstimationCalculation.remove.defaultContext({
      estimationId: currentEstimation.id,
      estimationVersion: currentEstimation.version,
      calculationId: calculation.id,
    })
  }

  return prevDeleteGroup(params)
})

const prevRemoveAll = smApi.UserClassifier.removeAll.use.getCurrent()

// Переопределяем вызов эндпоинта для удаления правила перед удалением группы (это важно, иначе сломается бэкенд)
smApi.UserClassifier.removeAll.use(async params => {
  // TODO getState()
  const calculations =
    estimationService.estimationCalculation.calculation$.getState()
  const currentEstimation =
    estimationService.estimation.currentEstimation$.getState()

  const groups = params.items.filter(({ type }) =>
    isGroupType(apiToNodeTypeMap[type]),
  )

  const calculationsList = groups
    .map(({ id }) => {
      const calculation = Object.values(calculations).find(
        c => c && c.groupId === id,
      )
      return calculation
    })
    .filter(isNotEmpty)

  await removeAllRules(calculationsList, {
    estimationId: currentEstimation!.id,
    estimationVersion: currentEstimation!.version,
  })

  return prevRemoveAll(params)
})

dependencyCheckedModel.checked$.updates.watch(checkedMap => {
  if (Object.keys(checkedMap).length > 0) {
    editorCheckedModel.resetChecked()
  }
})

editorCheckedModel.checked$.updates.watch(checkedMap => {
  if (Object.keys(checkedMap).length > 0) {
    dependencyCheckedModel.resetChecked()
  }
})

merge([
  smApi.BimReference.moveRefs.defaultContext.done,
  smApi.UserClassifierGroup.createGroupReference.defaultContext.done,
  smApi.BimReference.createRefs.defaultContext.done,
  smApi.BimReference.remove.defaultContext.done,
  smApi.UserClassifier.removeAll.defaultContext.done,
]).watch(() => {
  dependencyCheckedModel.resetChecked()
  editorCheckedModel.resetChecked()
})

const modelDependencyUpdatedEvent = createDependencyModelUpdatedEvent({
  currentEntity$: currentUserClassifier$,
  nodes$: classifierService.nodes$,
  notification: smApiNotification,
})

modelDependencyUpdatedEvent.watch(({ clsId, clsVersion, modelFromEvent }) => {
  smApi.getSourceClassifiersVersions.submit({
    id: clsId,
    version: clsVersion,
  })
})

modelDependencyUpdatedEvent.watch(({ clsId, clsVersion }) => {
  smApi.DependencyWithModels.getClassifierDependencyModels.defaultContext.submit(
    {
      id: clsId,
      version: clsVersion,
    },
  )
})

// Загрузка расширений для вьювера
setExtensionsToLoad(['AutofocusExtension', 'BIMSoft.Extensions.Filtering'])

smApi.UserClassifier.fetchFlatListItems.done.watch(({ params }) => {
  smApi.UserClassifierTree.DynamicGroupItem.getList.defaultContext.submit({
    classifierId: params.classifierId,
    classifierVersion: params.classifierVersion,
  })
})
