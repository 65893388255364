import * as React from 'react'
import { useStore, useStoreMap } from 'effector-react'

import { combine } from 'effector'

import {
  BimCategoryNode,
  BimElementNode,
  BimFamilyNode,
  BimModelNode,
  BimStandardSizeNode,
  DynamicBaseGroupNode,
  DynamicGeneratedGroupNode,
  UserClassifierGroupNode,
} from '@gmini/common/lib/classifier-service/Node'

import { SimpleConditions } from '@gmini/common/lib/components'

import { useReadonlyMode } from '@gmini/common'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { estimationService } from '../../../services/estimationService'

import { useCurrentCalculation } from '../../CurrentCalculation/useCurrentCalculation'

import {
  selectedPropertiesById$,
  selectProperties,
  selectPropertiesPending$,
} from '../conditions/simpleConditions'
import { useEstimation } from '../../CurrentEstimation'
import { getSourceGroupId } from '../../EstimationEditor/model/group-tree'
import { useElementPropertyList } from '../properties/useElementPropertyList'

const emptyArray: smApi.UserClassifierGroup.Property[] = []

export type SimpleConditionsContainerProps = {
  currentGroup:
    | UserClassifierGroupNode
    | DynamicBaseGroupNode
    | DynamicGeneratedGroupNode
    | BimElementNode
    | BimFamilyNode
    | BimCategoryNode
    | BimStandardSizeNode
    | BimModelNode
  widthFormulaResizableCol: number
}

const selectPending$ = combine(
  [
    selectPropertiesPending$,
    api.EstimationCalculation.create.defaultContext.pending$,
    api.EstimationCalculation.remove.defaultContext.pending$,
  ],
  values => values.some(Boolean),
)

export const SimpleConditionsContainer =
  React.memo<SimpleConditionsContainerProps>(
    ({ currentGroup, widthFormulaResizableCol }) => {
      const currentEstimation = useStore(
        estimationService.estimation.currentEstimation$,
      )

      const currentCalculation = useCurrentCalculation()

      const { pending: estimationPending } = useEstimation({
        estimationService,
      })

      const groupId = getSourceGroupId(currentGroup)

      const _selectedPropertiesIds = useStoreMap({
        store: selectedPropertiesById$,
        keys: [groupId],
        fn: (selectedPropertiesById, [id]) =>
          id && id in selectedPropertiesById ? selectedPropertiesById[id] : [],
      })
      const selectedPropertiesIds = currentCalculation
        ? _selectedPropertiesIds
        : []
      const elementPropsMap = useStore(estimationService.elementProps.byId$)

      const { propertyElementList, pending: listPending } =
        useElementPropertyList()

      const pending = useStore(selectPending$)
      const { readonlyMode } = useReadonlyMode()

      return (
        <SimpleConditions
          selectedPropertiesIds={selectedPropertiesIds}
          selectProperties={propertiesIds => {
            if (propertiesIds && currentEstimation) {
              if (!currentCalculation) {
                api.EstimationCalculation.create.defaultContext({
                  estimationId: currentEstimation.id,
                  estimationVersion: currentEstimation.version,
                  groupId: currentGroup.id,
                  simpleFields: [
                    ...propertiesIds.map(id => ({
                      elementPropertyId: id,
                    })),
                  ],
                  complexFields: [],
                })
              } else {
                selectProperties({
                  estimationId: currentEstimation.id,
                  estimationVersion: currentEstimation.version,
                  properties: propertiesIds,
                  calculationId: currentCalculation.id,
                  groupId: currentGroup.id,
                })
              }
            }
          }}
          availableProperties={propertyElementList || emptyArray}
          listPending={listPending}
          propertiesById={elementPropsMap}
          selectPending={pending}
          title='Простые колонки'
          disabled={readonlyMode.enabled || estimationPending}
          widthFormulaResizableCol={widthFormulaResizableCol}
        />
      )
    },
  )

SimpleConditionsContainer.displayName = 'SimpleConditionsContainer'
