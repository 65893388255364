import { Box } from '@gmini/ui-kit/lib/Box'
import React, { useCallback, useEffect, useState } from 'react'

import {
  FormulaInput,
  FormulaOperator,
  Suggestion,
} from '@gmini/ui-kit/lib/FormulaInput'

import { TextField, WarningOutlined } from '@gmini/ui-kit'

import { deleteCondition, updateConditionField } from '../conditions'

import { ConditionLayout } from './ConditionLayout'
import { Note, WarningWrapper } from './ConditionItem.styled'
import { InputNames } from './types'

export type ComplexFieldItem = {
  name: string
  formula: string
  unit: string
}

export type ConditionItemProps = {
  onValidate: (formula: string) => Promise<boolean> | boolean
  name: string
  formula: string
  unit: string
  allSuggestions: Suggestion[]
  suggestionsInFormula: Suggestion[] | undefined
  disabled: boolean
  idx: number
  widthFormulaResizableCol: number
  calculationId?: number
  errorName?: string
}

const operators: FormulaOperator[] = [
  // { name: '+' },
  // { name: '-' },
  // { name: '*' },
  // { name: '/' },
]

export const ConditionItem = React.memo<ConditionItemProps>(
  ({
    onValidate,
    allSuggestions,
    suggestionsInFormula = [],
    disabled,
    idx,
    widthFormulaResizableCol,
    formula,
    unit,
    calculationId,
    name,
    errorName,
  }) => {
    const [isValid, setIsValid] = useState(true)
    const [isHasSpecChar, setIsHasSpecChar] = useState(false)

    const validate = React.useCallback(async () => {
      const valid = await onValidate(formula)
      setIsValid(valid)
    }, [formula, onValidate])

    const formulaHandler = useCallback(
      (value: string) => {
        if (calculationId) {
          updateConditionField({
            calculationId,
            fieldName: InputNames.formula,
            newFieldValue: value,
            idxCondition: idx,
          })
        }
      },
      [calculationId, idx],
    )

    const onChangeFormula = React.useCallback(
      ({ hasSpecialChar, nextStr }) => {
        setIsHasSpecChar(hasSpecialChar)
        const nextValue = nextStr.toUpperCase()
        formulaHandler(nextValue)
      },
      [formulaHandler],
    )

    const onBlurFormula = React.useCallback(() => {
      validate()
    }, [validate])

    const onClearFormula = React.useCallback(() => {
      formulaHandler('')
    }, [formulaHandler])

    const onChangeUnit = React.useCallback(
      (value: string) => {
        if (value.length > 10) {
          return
        }

        if (calculationId) {
          updateConditionField({
            calculationId,
            fieldName: InputNames.unit,
            newFieldValue: value,
            idxCondition: idx,
          })
        }
      },
      [calculationId, idx],
    )

    const onChangeName = useCallback(
      (value: string) => {
        if (calculationId) {
          updateConditionField({
            calculationId,
            fieldName: InputNames.name,
            newFieldValue: value,
            idxCondition: idx,
          })
        }
      },
      [calculationId, idx],
    )

    useEffect(() => {
      validate()
    }, [validate])

    let errorText = ''

    if (isHasSpecChar) {
      errorText = `Формула не будет сохранена, т.к. вы использовали запрещенную комбинацию символов`
    } else if (!isValid && formula) {
      errorText = `Формула содержит ошибку, проверьте корректность`
    }

    const isSmallWidth = widthFormulaResizableCol < 382

    const isAdaptiveModeNode = widthFormulaResizableCol <= 450

    return (
      <div>
        <ConditionLayout
          disabled={!!disabled}
          onRemove={() => {
            if (calculationId) {
              deleteCondition({
                calculationId,
                idxCondition: idx,
              })
            }
          }}
          header={
            <Box
              display='flex'
              justifyContent='space-between'
              width='100%'
              flexDirection={isSmallWidth ? 'column' : 'row'}
              style={{ gap: '14px 16px' }}
            >
              <Box width='100%'>
                <TextField
                  value={name}
                  onChange={onChangeName}
                  placeholder='Название колонки'
                  disabled={disabled}
                  background='#fff'
                  error={Boolean(errorName)}
                />
              </Box>

              <Box width={isSmallWidth ? '100%' : '110px'}>
                <TextField
                  value={unit}
                  onChange={onChangeUnit}
                  placeholder='ед.изм.'
                  disabled={disabled}
                  background='#fff'
                />
              </Box>
            </Box>
          }
          formulaInput={
            <FormulaInput
              sourceStr={formula}
              allSuggestions={allSuggestions}
              operators={operators}
              onChange={onChangeFormula}
              onBlur={onBlurFormula}
              onClear={onClearFormula}
              suggestionsInFormula={suggestionsInFormula}
              disabled={disabled}
              error={Boolean(errorText)}
            />
          }
          noteText={
            (errorText || errorName) && (
              <Note adaptiveMode={isAdaptiveModeNode}>
                <WarningWrapper adaptiveMode={isAdaptiveModeNode}>
                  <WarningOutlined />
                </WarningWrapper>
                {errorText || errorName}
              </Note>
            )
          }
        ></ConditionLayout>
      </div>
    )
  },
)

ConditionItem.displayName = 'ConditionItem'
