import { useEffect, useCallback, useState, memo } from 'react'
import { useHistory } from 'react-router-dom'

import {
  ExplorerLayout,
  PageTitle,
  UserProfile,
  HeaderLogoWrap,
  Logo,
} from '@gmini/ui-kit'

import { useKeycloak } from '@react-keycloak/web'

import { userInfo$ } from '@gmini/common/lib/auth/auth'

import { useStore } from 'effector-react'

import { goals } from '@gmini/common/lib/metrika'

import * as smApi from '@gmini/sm-api-sdk'

import { useNavbar, SwitchProject, getModulesLinkFromEnv } from '@gmini/common'

import { useQuery } from '@gmini/utils'

import { EstimationExplorer } from '../EstimationExplorer'

import { envLinks } from '../../config/config'

import {
  selectedProject$,
  setSelectedProject,
} from '../EstimationExplorer/Model'

const PANEL_WIDTH_PX = 350

export const VolumesExplorerPage = memo(() => {
  const history = useHistory()
  const [panelsCount, setPanelsCount] = useState(1)
  const { keycloak } = useKeycloak()
  const [projectList, setProjectList] = useState<smApi.Project[]>([])
  const [openedSwitchProjectPopup, setOpenedSwitchProjectPopup] =
    useState(false)

  const query = useQuery()
  const selectedProjectUrn = query.get('projectUrn')

  const userInfo = useStore(userInfo$)
  const selectedProject = useStore(selectedProject$)
  const fetchProjectListPending = useStore(
    smApi.Project.fetchList.defaultContext.pending$,
  )

  const fullName = userInfo ? userInfo.name : ''

  useEffect(() => {
    if (!selectedProjectUrn && projectList.length) {
      const defaultProject = projectList[0]
      setSelectedProject(defaultProject)
      history.replace(
        `${history.location.pathname}?projectUrn=${defaultProject.urn}`,
      )
      return
    }

    const project = projectList.find(p => p.urn === selectedProjectUrn)
    if (project) {
      setSelectedProject(project)
    }
  }, [history, projectList, selectedProjectUrn])

  useEffect(() => {
    setTimeout(() => {
      smApi.Project.fetchList.defaultContext.submit()
    }, 1000) //TODO Костыль для того чтобы токен успел засетиться в стор перед запросом
  }, [])

  useEffect(() => {
    const fetchProjectSubscription =
      smApi.Project.fetchList.defaultContext.doneData.watch(({ list }) => {
        setProjectList(list)
      })

    return () => fetchProjectSubscription.unsubscribe()
  }, [])

  const { Navbar } = useNavbar({
    navModules: getModulesLinkFromEnv(envLinks, selectedProjectUrn),
    title: 'Объёмы',
    icon: (
      <HeaderLogoWrap>
        <Logo />
      </HeaderLogoWrap>
    ),
  })

  const onEstimationOpen = useCallback(
    ({ id }: { readonly id: number }) => history.push(`/estimation/${id}`),
    [history],
  )

  const onLogout = useCallback(() => {
    keycloak.logout()
  }, [keycloak])

  return (
    <>
      <PageTitle>Каталог расчётов</PageTitle>
      <ExplorerLayout
        navBar={Navbar}
        switchProjectSelect={
          <SwitchProject
            projectList={projectList}
            disabled={fetchProjectListPending}
            loading={fetchProjectListPending}
            onChangeProject={(project: smApi.Project) => {
              setSelectedProject(project)
              history.replace(
                `${history.location.pathname}?projectUrn=${project.urn}`,
              )
            }}
            selectedProjectName={selectedProject?.name || ''}
            onToggle={() => setOpenedSwitchProjectPopup(prev => !prev)}
            opened={openedSwitchProjectPopup}
            onClose={() => setOpenedSwitchProjectPopup(false)}
          />
        }
        panelsCount={panelsCount + 1}
        panelWidth={PANEL_WIDTH_PX}
        headerRightSide={
          <UserProfile
            userName={fullName}
            onLogout={onLogout}
            onProfileClick={() => goals.showUserMenu()}
          />
        }
        body={
          <EstimationExplorer
            panelWidth={PANEL_WIDTH_PX}
            onEstimationOpen={onEstimationOpen}
            panelsCount={setPanelsCount}
            selectedProject={selectedProject}
          />
        }
      />
    </>
  )
})

VolumesExplorerPage.displayName = 'VolumesExplorerPage'
