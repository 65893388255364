import React, { memo, useCallback, useEffect } from 'react'
import { Box } from '@gmini/ui-kit/lib/Box'

import {
  combine,
  createEvent,
  forward,
  guard,
  merge,
  restore,
  sample,
} from 'effector'

import { useStore } from 'effector-react'

import { useHistory } from 'react-router-dom'
import { prop } from 'ramda'

import { useKeycloak } from '@react-keycloak/web'
import { PageContentContainer, useNavbar } from '@gmini/common/lib/components'

import {
  Header,
  HeaderLogoWrap,
  Logo,
  SeparatorList,
  PlusCircle,
  IconButton,
  LayersWithoutSubstrate,
  Tooltip,
  FolderWithPlus,
  CountBig,
  CheckList as CheckListIcon,
  Resizable,
  ResizablePanel,
  ResizableRefApi,
  ResizableColumnStateItem,
  Cover,
  Substrate,
  FolderWithoutSubstrate,
  WithCursorIcon,
} from '@gmini/ui-kit'

import { TreeLoader } from '@gmini/ui-kit/lib/TreeLoader/TreeLoader'

import { isNotEmpty } from '@gmini/utils'

import { getNode } from '@gmini/common/lib/classifier-service'

import { createUserClassifierRepoTreeModel } from '@gmini/common/lib/classifier-editor/SelectDependencies/ClassifiersTree/createTreeModel'

import * as forgeViewer from '@gmini/common/lib/forge-viewer'
import { createModelManageMenu } from '@gmini/common/lib/classifier-editor/ModelManageMenu'
import * as filter from '@gmini/common/lib/classifier-editor/DependencyTree/Filters/InclusionFilter/FilterModel'

import {
  DragLayer,
  isApiFlatNode,
  ModelSelect,
  SelectDependencies,
  openExplorer,
  createSelectDependenciesParams,
  createViewerDisplayModel,
  selectedModels as selectedModelsService,
  createSearchModel,
} from '@gmini/common/lib/classifier-editor'

import { useInclusionFilter } from '@gmini/common/lib/classifier-editor/DependencyTree/Filters'

import { createModelStoreService } from '@gmini/common/lib/classifier-editor/ModelSelect/modelStore'

import { createModelsTree } from '@gmini/common/lib/classifier-editor/SelectDependencies/ModelsTree'

import { createClassifiersTree } from '@gmini/common/lib/classifier-editor/SelectDependencies/ClassifiersTree/ClassifiersTree'

import { createExpandModel } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/expandModel'

import { DependencyTreeWrap } from '@gmini/common/lib/classifier-editor/DependencyTree/DependencyTreeWrap'

import { setCurrentGroup } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/groupModel'

import {
  useDynamicGroupMode,
  useReadonlyMode,
  VersionButton,
  VersionHistory,
  ProjectBadge,
  getModulesLinkFromEnv,
} from '@gmini/common'

import { userInfo$ } from '@gmini/common/lib/auth/auth'

import {
  useNamedVersions,
  useVersionName,
} from '@gmini/common/lib/components/VersionSwitch/NamedVersions'

import { VersionList } from '@gmini/common/lib/components/VersionSwitch/VersionList'
import { useContextMenu } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'
import { createVersionHistoryStore } from '@gmini/common/lib/components/VersionSwitch/versionHistoryStore'
import {
  compareDates,
  fromDateTimeToDate,
  sortByDate,
} from '@gmini/common/lib/components/VersionSwitch/common'
import { createBimFileSubscriptions } from '@gmini/common/lib/classifier-editor/bimFileSubscriptions'

import { goals } from '@gmini/common/lib/metrika'

import { createStatusPanel } from '@gmini/common/lib/classifier-editor/StatusPanel'
import { getBimNode } from '@gmini/common/lib/classifier-editor/Search/utils'

import {
  getViewerId,
  validateModelTypes,
} from '@gmini/common/lib/classifier-editor/Common/utils'
import * as smApi from '@gmini/sm-api-sdk'

import {
  isAssemblyClassifierNode,
  isReferenceNode,
  isUserClassifierNode,
  Node,
} from '@gmini/common/lib/classifier-service/Node'

import {
  EstimationEntity,
  buildEstimationStatusKey,
} from '@gmini/common/lib/Services/EstimationService'

import { adapter } from '@gmini/common/lib/classifier-service/adapters'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { classifierService } from '../../services/classifierService'
import { notificationService } from '../../services/notificationService'

import { estimationService } from '../../services/estimationService'
import { CurrentEstimation } from '../CurrentEstimation'
import { useSelectedGroupNode } from '../CurrentCalculation'
import { GroupSettings } from '../GroupSettings'

import { currentUserClassifier$, projectUrn$ } from '../CurrentUserClassifier'
import { conditionChanged$ } from '../GroupSettings/conditions'
import { logEventEditor } from '../../config/amplitude'
import { userClassifierRepoService } from '../../services/userClassifierRepoServiceConnect'
import { fetchForgeToken } from '../../services/forgeService'

import {
  checkStatusGrouping,
  groupingProcessStatus$,
  resetGroupingStatus,
  startGrouping,
} from '../GroupSettings/GroupingsContainer/GroupingContainer.model'

import { envLinks } from '../../config'

import { RunEstimation } from './RunEstimation'

import './model/current/estimation.init'
import './model/current/fetchConditions.init'
import { sourceClassifiersLoaded$ } from './model/current/fetchSourceCls'
import { dynamicGroupPending$, EditorTreeWrap } from './EditorTreeWrap'

import {
  dependencyCheckedModel,
  editorCheckedModel,
} from './model/current/checkedModel'
import { dependencyTreeModel } from './model/current/dependencyTreeModel'
import { treeModel } from './model/current/editorTreeModel'
import {
  fetchAllInclusion,
  filteredFlatTree$,
  inclusionStore$,
  loadOnceNodeInclusion,
} from './model/inclusionModel'

import { dependencyExpandModel } from './model/dependencyExpandModel'
import {
  CountIconWrap,
  DynamicModeIconButton,
  DynamicModeText,
  DynamicModeWrapper,
  DynamicTooltipBody,
  DynamicTooltipHeader,
  DynamicTooltipWrapper,
  EditorPanelHeader,
  EditorPanelHeaderIcon,
  EditorPanelHeaderTitle,
  BrandContainer,
  BrandTitle,
  BrandSecondaryWrapper,
} from './EstimationEditorPage.styled'
import { EstimationReport } from './EstimationReport/EstimationReport'
import { createReportService } from './EstimationReport/model/reportService'
import { NotActual } from './EstimationReport/Report/NotActual'
import { searchSourceModel } from './model/searchSourceModel'
import { expandModel } from './EditorTreeWrap/expandModel'
import { dynamicFlatItemsInitialFetchService } from './model/current/dynamicFlatItemsFetch.init'
import { truncateText } from './truncateText'
import { ReportSubtotals } from './EstimationReport/Subtotals/reportSubtotals'
import { dynamicGroupConditions$ } from './model/dynamic-conditions.store'
import { viewerDerivatives$ } from './model/current/fetchViewerModelDerivatives'
import { currentProject$ } from './model/current/project'

enum TreeSections {
  viewer,
  dependencies,
  tree,
  formula,
  results,
}

// TODO разобрать конструкторы и сторы до объявления компонента по файлам
const versionHistoryPending$ = combine(
  [
    api.Estimation.fetchNamedVersions.defaultContext.pending$,
    api.Estimation.fetchVersionDates.defaultContext.pending$,
    // smApi.Estimation.fetchVersionByDate.defaultContext.pending$,
  ],
  pendings => pendings.some(Boolean),
)

const statusPending$ = api.Estimation.fetchStatus.defaultContext.pending$

const { resetBimFileIds } = createBimFileSubscriptions({
  subscribe: notificationService.subscriptions.subscribeBimFile,
  unsubscribe: notificationService.subscriptions.unsubscribeBimFile,
})

const tree$ = combine(
  dependencyTreeModel.flatTree$,
  treeModel.flatTree$,
  (treeModel, dependencyTreeModel) => [...treeModel, ...dependencyTreeModel],
)

//FSK-655/29

merge([
  smApi.BimReference.remove.doneData,
  smApi.BimReference.create.doneData,
  smApi.BimReference.move.doneData,
  smApi.UserClassifierGroup.create.doneData,
  smApi.UserClassifierGroup.move.doneData,
  smApi.UserClassifierGroup.rename.doneData,
  smApi.UserClassifierGroup.remove.doneData,
  smApi.UserClassifier.removeAll.doneData.map(({ id }) => ({
    parentClassifierId: id,
  })),
]).watch(({ parentClassifierId: id }) => {
  smApi.UserClassifier.getMostRecent.defaultContext.submit({ id })
})

const inclusionStatusWithTranscript = {
  PARENT_INCLUDED: 'Родительский элемент добавлен в правило',
  SELF_INCLUDED: 'Элемент добавлен в правило',
  CHILDREN_INCLUDED: 'Один из дочерних элементов добавлен в правило',
}

const selectedModels$ = selectedModelsService.models$.map(
  models => models?.map(({ viewerId }) => viewerId) || [],
)

const modelStoreService = createModelStoreService((node, viewerRef) =>
  getViewerId({
    node,
    viewerRef,
    nodes: classifierService.nodes$.getState(),
    getNodeFunc: getNode,
    validateModelTypes,
  }),
)

sample({
  source: estimationService.estimation.currentEstimation$,
  clock: merge([
    api.Estimation.renameVersion.doneData,
    api.Estimation.removeVersionName.doneData,
  ]),
  fn: (estimation, versionData) => ({ estimation, versionData }),
}).watch(({ estimation, versionData }) => {
  if (estimation?.version === versionData.version) {
    api.Estimation.fetch.defaultContext(estimation)
  }
})

const { subscriptions, message } = notificationService

const notification = message.filter({ fn: smApi.NotificationEvent.is })

const { versionDates$, versions$, removeVersion } = createVersionHistoryStore({
  fetchDates: api.Estimation.fetchVersionDates.doneData,
  fetchVersions: api.Estimation.fetchVersionByDate.done.map(
    ({ params, result: { versions } }) => ({
      versionDate: params.versionDate,
      versions,
    }),
  ),
  fetchNamedVersionDates: api.Estimation.fetchNamedVersions.doneData.map(
    ({ versions }) => ({
      versionDates: [
        ...new Set(
          versions.map(version => fromDateTimeToDate(version.createdDate)),
        ),
      ],
    }),
  ),
  fetchNamedVersions: api.Estimation.fetchNamedVersions.doneData.map(
    ({ versions }) =>
      versions.map(version => ({
        versionDate: fromDateTimeToDate(version.createdDate),
        versions: versions
          .filter(vrs => compareDates(vrs.createdDate, version.createdDate))
          .sort((a, b) => sortByDate(a.createdDate, b.createdDate)),
      })),
  ),
  versionNameChanged: merge([
    api.Estimation.renameVersion.doneData,
    api.Estimation.removeVersionName.doneData,
  ]).map(data => ({
    versionDate: fromDateTimeToDate(data.createdDate),
    version: data,
  })),
})

const { addedDependencyIds$, currentModelsByClassifierMap$ } =
  createSelectDependenciesParams({
    nodes$: classifierService.nodes$,
    currentEntity$: currentUserClassifier$,
  })

const { ModelsTree, bimFile$ } = createModelsTree({
  addedDependencyIds$,
  inclusionStatus$: inclusionStore$,
  currentModelsByClassifierMap$,
  currentEntity$: currentUserClassifier$,
  notification,
})

const userClassifierTree$ = createUserClassifierRepoTreeModel(
  userClassifierRepoService,
  projectUrn$,
)

const { ClassifiersTree } = createClassifiersTree({
  inclusionStatus$: inclusionStore$,
  addedDependencyIds$,
  tree$: userClassifierTree$,
  currentUserClassifier$,
})

const { ModelManageMenu } = createModelManageMenu({
  classifierService,
  currentEntity$: currentUserClassifier$,
  bimFile$,
})

const resetEstimationStatus = createEvent<void>()

const currentEstimationStatus$ = sample({
  source: estimationService.estimation.currentEstimation$,
  clock: estimationService.estimation.estimationStatus$,
  fn: (estimation, statusMap) =>
    estimation && statusMap
      ? statusMap[
          buildEstimationStatusKey({
            estimationId: estimation.id,
            estimationVersion: estimation.version,
          })
        ] || null
      : null,
}).reset(resetEstimationStatus)

const estimationFinished = currentEstimationStatus$.updates.filter({
  fn: value => value === 'Finished',
})

const estimationStarted = currentEstimationStatus$.updates.filter({
  fn: value => value === 'Started',
})

const { previewResult$, containerMounted, reportPending$, resetPreviewResult } =
  createReportService({
    entity$: estimationService.estimation.currentEstimation$,
    fetchTrigger: estimationFinished,
    status$: currentEstimationStatus$,
    reset: api.Estimation.start.trigger,
  })

export const expandVersionModel = createExpandModel()

const setDependencyRootNodes = createEvent<Node[]>()
const dependencyRootNodes$ = restore(setDependencyRootNodes, [])

dependencyTreeModel.flatTree$.updates.watch(flatTree => {
  // TODO разобраться почему нет апдейтов от стора dependencyTreeModel.flatTree$ в подписке clock(sample)
  // Информация будет всегда актуальной так как flatTree обновится после апдейта nodes$
  // Сделано во избежания конфликта со стором tree$

  const nodes = classifierService.nodes$.getState()

  setDependencyRootNodes(
    flatTree
      .filter(isApiFlatNode)
      .filter(({ path }) => path.length === 1)
      .map(({ ref }) => getNode(nodes, ref))
      .filter(isNotEmpty),
  )
})

const parentData$ = sample({
  clock: dependencyRootNodes$,
  source: classifierService.nodes$,
  fn: (nodes, nodeArr) =>
    nodeArr
      .map(node => {
        if (isReferenceNode(node) && node.type === 'BimModelReferenceNode') {
          const model = getNode(nodes, node.element)
          if (!model) {
            return null
          }
          return { id: node.parentClassifierId, name: model.name }
        } else if (isAssemblyClassifierNode(node) && node.assemblyModelRef) {
          const model = getNode(nodes, node.assemblyModelRef.element)
          if (!model) {
            return null
          }
          return { id: node.id, name: model.name }
        } else if (isUserClassifierNode(node)) {
          return { id: node.id, name: node.name }
        }

        return null
      })
      .filter(isNotEmpty),
})

// Инкапсулировать логику в common после GT-984 - Front: Исправить проблему: "circular dependencies" в репозиториях
const modelUpdated = notificationService.message
  .filter({ fn: smApi.NotificationEvent.Update.is })
  .map(prop('payload'))
  .filter({ fn: smApi.BimModel.is })
  .filter({
    fn: model =>
      model.modelType === 'ForgeModel' && model.status === 'ImportCompleted',
  })

guard({
  clock: modelUpdated,
  source: currentUserClassifier$.map(cls =>
    cls ? { classifierId: cls.id, classifierVersion: cls.version } : null,
  ),
  filter: Boolean,
  target: smApi.UserClassifier.fetchFlatListDependencies.defaultContext.submit,
})

export const { Tags, searchModel } = createSearchModel({
  nodes$: classifierService.nodes$,
  currentEntity$: currentUserClassifier$,
  tree$,
  searchInDynamicGroups: true,
})

export const { StatusPanel: DependenciesStatusPanel } = createStatusPanel({
  checkedModel: dependencyCheckedModel,
  flatTree$: dependencyTreeModel.flatTree$,
  searchNodeInfo$: searchModel.searchNode$,
})

export const { StatusPanel: EditorTreeStatusPanel } = createStatusPanel({
  checkedModel: editorCheckedModel,
  flatTree$: treeModel.flatTree$,
  searchNodeInfo$: searchModel.searchNode$,
})

createViewerDisplayModel({
  currentEntity$: currentUserClassifier$,
  nodes$: classifierService.nodes$,
  searchModel,
  getViewerId: (node, viewerRef) =>
    getViewerId({
      node,
      viewerRef,
      nodes: classifierService.nodes$.getState(),
      getNodeFunc: getNode,
      validateModelTypes,
    }),
  getClsModels: (clsId, clsVersion) => {
    const dependenciesWithModels =
      modelStoreService.dependenciesWithModels$.getState()
    const models =
      dependenciesWithModels
        .find(
          ({ classifier }) =>
            classifier.id === clsId && classifier.version === clsVersion,
        )
        ?.models.map(m => adapter(m)) || null

    if (!models) {
      throw new Error(
        `Models not found in classifier id: ${clsId}, version: ${clsVersion}`,
      )
    }

    // Now we are sure that model arr contain  only bim360 or Gstation models
    validateModelTypes(models)

    return models
  },
})

const searchedBimNode$ = sample({
  source: classifierService.nodes$,
  clock: searchModel.searchNode$,
  fn: (nodes, searchedData) => {
    if (!searchedData) {
      return null
    }
    const element = getBimNode(searchedData.node, nodes)

    return element || null
  },
}).reset(searchModel.resetSearchNode)

forward({
  from: smApi.UserClassifier.fetchDynamicGroupFlatItems.trigger,
  to: searchModel.fetchParentDynamicGroups,
})

dynamicFlatItemsInitialFetchService(
  currentUserClassifier$,
  classifierService.reset,
)

export const EstimationEditorPage = memo(
  ({
    currentEstimation,
    isCurrentVersion,
  }: {
    currentEstimation: EstimationEntity
    isCurrentVersion: boolean
  }) => {
    const userInfo = useStore(userInfo$)
    const history = useHistory()
    const versionHistoryPending = useStore(versionHistoryPending$)
    const conditionChanged = useStore(conditionChanged$)
    const estimationStatus = useStore(currentEstimationStatus$)
    const reportPending = useStore(reportPending$)
    const statusPending = useStore(statusPending$)
    const selectedModels = useStore(selectedModels$)
    const currentUserClassifier = useStore(currentUserClassifier$)
    const groupingProcessStatus = useStore(groupingProcessStatus$)
    const inCreateNode = useStore(treeModel.inCreateNode$)
    const currentProject = useStore(currentProject$)
    const viewerDerivatives = useStore(viewerDerivatives$)

    const fetchFlatListDependenciesPending = useStore(
      smApi.UserClassifier.fetchFlatListDependencies.defaultContext.pending$,
    )
    const fetchFlatListItemsPending = useStore(
      smApi.UserClassifier.fetchFlatListItems.defaultContext.pending$,
    )
    const renameEstimationPending = useStore(
      api.Estimation.rename.defaultContext.pending$,
    )

    const fetchListProjectPending = useStore(
      smApi.Project.fetchList.defaultContext.pending$,
    )

    const [selectViewerRefs, setSelectViewerRefs] = React.useState<
      Record<string, string[]>
    >({})

    React.useEffect(
      () => () => modelStoreService.resetDependenciesWithModels(),
      [],
    )

    React.useEffect(() => {
      const subscription = searchModel.resetSearchNode.watch(() => {
        setSelectViewerRefs({})
      })

      return () => {
        subscription.unsubscribe()
      }
    }, [])

    const { keycloak } = useKeycloak()

    const versionDates = useStore(versionDates$)
    const versions = useStore(versions$)

    const [openedResultCalculating, setOpenedResultCalculating] =
      React.useState(false) //TODO Костыль для того, чтобы корректно совмещались ноды в дереве с нодами на вкладке "Результат расчёта" https://gmini.atlassian.net/browse/GS-335?focusedCommentId=10544

    const { InclusionFilterButton, InclusionFilterContent } =
      useInclusionFilter()

    const selectedGroup = useSelectedGroupNode({
      nodes$: classifierService.nodes$,
    })

    const { dynamicGroupMode, toggleDynamicMode, resetDynamicGroupMode } =
      useDynamicGroupMode()

    const estimationId = currentEstimation?.id
    const classifierId = currentEstimation?.classifierId

    useEffect(() => {
      if (estimationId && classifierId) {
        subscriptions.subscribeEstimation({ estimationId, classifierId })
      }

      return () => {
        if (estimationId && classifierId) {
          subscriptions.unsubscribeEstimation({
            estimationId,
            classifierId,
          })
        }
      }
    }, [estimationId, classifierId])

    const onToggleDynamicMode = React.useCallback(() => {
      if (currentEstimation) {
        setCurrentGroup(null)
        toggleDynamicMode()
        expandModel.resetExpanded()
        editorCheckedModel.resetChecked()
      }
    }, [currentEstimation, toggleDynamicMode])

    const createRootGroup = useCallback(() => {
      treeModel.setInCreateRootNode()
    }, [])

    useEffect(
      () => () => {
        estimationService.estimation.resetCurrent()
        setCurrentGroup(null)
        resetDynamicGroupMode()
        classifierService.reset()
        resetBimFileIds()
        resetPreviewResult()
        resetEstimationStatus()
        resetGroupingStatus()
      },
      [resetDynamicGroupMode],
    )

    useEffect(() => {
      filter.setStorageKey(
        currentEstimation
          ? `estimationDependencyFilter:${currentEstimation.id}`
          : null,
      )

      return filter.reset
    }, [currentEstimation])

    // TODO Рефакторинг логики перезапроса статусов динамических группировок
    useEffect(() => {
      if (!currentUserClassifier) {
        return
      }
      checkStatusGrouping({
        classifierId: currentUserClassifier.id,
        version: currentUserClassifier.version,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dynamicGroupMode])

    // TODO Рефакторинг логики перезапроса статусов динамических группировок
    useEffect(() => {
      const statusSubscribe = smApi.DynamicGroup.status.doneData.watch(
        params => {
          if (params.status === 'ErrorTimedOut') {
            return
          }

          if (params.status === 'NotStarted' && currentUserClassifier) {
            startGrouping({
              classifierId: currentUserClassifier.id,
              version: currentUserClassifier.version,
            })
          }

          if (
            params.status === 'Finished' &&
            currentUserClassifier &&
            dynamicGroupMode
          ) {
            smApi.UserClassifier.fetchDynamicGroupFlatItems.defaultContext({
              classifierId: currentUserClassifier.id,
              classifierVersion: currentUserClassifier.version,
            })
          }
        },
      )

      return () => statusSubscribe.unsubscribe()
    }, [classifierId, currentUserClassifier, dynamicGroupMode])

    const { readonlyMode } = useReadonlyMode()

    const [openVersionHistory, setOpenVersionHistory] = React.useState(false)

    const { NamedVersionsSwitch, namedVersions, closeNamedVersions } =
      useNamedVersions({
        getNamedVersions: async () =>
          !!(await api.Estimation.fetchNamedVersions.defaultContext({
            estimationId: currentEstimation.id,
          })),
        onDisable: () => {
          expandVersionModel.resetExpanded()
          api.Estimation.fetchVersionDates.defaultContext({
            estimationId: currentEstimation.id,
          })
        },
        onActive: () =>
          versionDates &&
          expandVersionModel.expandAll(versionDates.versionDates),
        disabled: versionHistoryPending,
      })

    const onOpenVersionHistory = React.useCallback(async () => {
      await api.Estimation.fetchVersionDates.defaultContext({
        estimationId: currentEstimation.id,
      })

      setOpenVersionHistory(true)
    }, [currentEstimation])

    const { ChangeVersionNameDialog, setChangeNameDialog, changeNameDialog } =
      useVersionName<smApi.VersionData>({
        onSubmitChangeName: async ({ name, version }) =>
          !!(await api.Estimation.renameVersion.defaultContext({
            estimationId: currentEstimation.id,
            estimationVersion: version,
            versionName: name,
          })),
      })

    const { ContextMenu, setCtxMenu } = useContextMenu<smApi.VersionData>([
      {
        title: 'Перейти к версии',
        onClick: ({ version }) => {
          window.open(
            `${window.location.origin}/estimation/${
              currentEstimation!.id
            }/version/${version}`,
            '_blank',
          )
        },
        show: item => item.version !== currentEstimation.version,
      },
      {
        title: 'Переименовать',
        onClick: item => {
          setChangeNameDialog(item)
        },
        show: item => !!item.name,
      },
      {
        title: 'Присвоить имя',
        onClick: item => {
          setChangeNameDialog(item)
        },
        show: item => !item.name,
      },
      {
        title: 'Удалить название',
        onClick: item => {
          api.Estimation.removeVersionName
            .defaultContext({
              estimationId: currentEstimation.id,
              estimationVersion: item.version,
            })
            .then(version => {
              if (namedVersions) {
                removeVersion({
                  versionDate: fromDateTimeToDate(version.createdDate),
                  version,
                })
              }
            })
        },
        show: item => !!item.name,
      },
    ])

    const editorChecked = useStore(editorCheckedModel.checked$)
    const dependencyChecked = useStore(dependencyCheckedModel.checked$)
    const dynamicGroupPending = useStore(dynamicGroupPending$)

    const onCloseVersionHistory = React.useCallback(() => {
      if (namedVersions) {
        closeNamedVersions()
      }
      setOpenVersionHistory(false)
    }, [closeNamedVersions, namedVersions])

    const fetchDependencyModels = React.useCallback(
      (params: { id: number; version: number }) => {
        smApi.DependencyWithModels.getClassifierDependencyModels.defaultContext(
          params,
        )
      },
      [],
    )

    const [panelsDebouncedState, setPanelsDebouncedState] = React.useState<
      ResizableColumnStateItem[]
    >([])

    const onColumnsWidthsChanged = React.useCallback(
      (state: ResizableColumnStateItem[]) => {
        setPanelsDebouncedState(state)
      },
      [],
    )

    const widthFormulaResizableCol =
      panelsDebouncedState[TreeSections.formula]?.widthPx || 0

    const widthColMoreMin = widthFormulaResizableCol > 310

    const widthTreeResizableCol =
      panelsDebouncedState[TreeSections.tree]?.widthPx || 0

    const notActualResult =
      conditionChanged || estimationStatus !== 'Finished' || statusPending

    const resizableRef = React.useRef<ResizableRefApi>(null)

    const dynamicGroupConditions = useStore(dynamicGroupConditions$)

    const onRenameEntity = (value: string) => {
      api.Estimation.rename.defaultContext.submit({
        name: value,
        parentFolderId: currentEstimation.parentFolderId,
        id: currentEstimation.id,
        version: currentEstimation.version,
      })
    }

    //Открываем панель результатов(если закрыта) когда расчет запустился
    React.useEffect(() => {
      const subscription = estimationStarted.watch(() => {
        if (resizableRef.current) {
          resizableRef.current.openPanel(TreeSections.results)
        }
      })
      return () => {
        subscription.unsubscribe()
      }
    }, [])

    const loadModelByUrl = !!(currentProject?.sourceType === 'GStation')

    const fetchToken =
      React.useCallback(async (): Promise<smApi.ForgeToken> => {
        if (loadModelByUrl) {
          return {
            accessToken: keycloak.token!,
            expiresAt: keycloak.tokenParsed!.exp!,
          }
        }

        const token = await fetchForgeToken()

        return token
      }, [keycloak, loadModelByUrl])

    const getViewerModelUrl = React.useCallback(
      (idFromUrn: string) => {
        let url = ''
        viewerDerivatives?.files.forEach(({ derivatives, urn }) => {
          // eslint-disable-next-line prefer-named-capture-group
          const match = urn.match(/urn:gstation\.dm\.file:(\d+)/u)

          if (match?.[1] === idFromUrn) {
            url = derivatives.find(d => d.type === '3D')?.view || ''
          }
        })

        if (!url) {
          throw new Error(`Url not found for model with urn: ${idFromUrn}`)
        }
        return url
      },
      [viewerDerivatives?.files],
    )

    const { NavbarDropDown, opened } = useNavbar({
      navModules: getModulesLinkFromEnv(envLinks, currentProject?.urn),
    })

    const projectBadge = (
      <ProjectBadge
        projectName={currentProject?.name || ''}
        loading={fetchListProjectPending}
      />
    )

    if (!currentUserClassifier) {
      return null
    }

    if (!currentProject) {
      return null
    }

    return (
      <>
        <DragLayer
          editorCheckedCount={Object.keys(editorChecked).length}
          depsCheckedCount={Object.keys(dependencyChecked).length}
        />
        <Box height='100%' display='flex' flexDirection='column'>
          <CurrentEstimation />
          <Header
            title={currentEstimation?.name}
            brandSecondary={
              <BrandSecondaryWrapper>{projectBadge}</BrandSecondaryWrapper>
            }
            titleSecondary={
              <>
                <VersionButton
                  onClick={onOpenVersionHistory}
                  isActualVersion={isCurrentVersion}
                  currentVersion={currentEstimation.version}
                  versionCreated={currentEstimation.versionCreatedDate}
                  versionName={currentEstimation.versionName}
                  pending={versionHistoryPending}
                  title={currentEstimation.name}
                  onRename={onRenameEntity}
                  renamePending={renameEstimationPending}
                />

                <Box ml={1} flexGrow={1}>
                  <Tags />
                </Box>
              </>
            }
            onLogout={() => keycloak.logout()}
            brand={
              <Tooltip
                enterDelay={400}
                title={opened ? '' : 'Выйти к списку инспекций'}
              >
                <BrandContainer
                  data-test-id='backToExplorer'
                  to={
                    currentProject ? `/?projectUrn=${currentProject?.urn}` : '/'
                  }
                  active={opened}
                >
                  <HeaderLogoWrap>
                    <Logo />
                  </HeaderLogoWrap>
                  <BrandTitle>Объёмы</BrandTitle>
                  {NavbarDropDown}
                </BrandContainer>
              </Tooltip>
            }
            userInfo={userInfo}
            onProfileClick={() => goals.showUserMenu()}
          />

          <PageContentContainer data-test-id='EstimateEditor'>
            <Resizable
              foldedPanelWidthPx={50}
              onWidthChangedDelay={50}
              ref={resizableRef}
              storageKey='EstimationEditorColumns'
              onWidthsChanged={onColumnsWidthsChanged}
            >
              <ResizablePanel
                header={
                  <Box
                    ml='-16px'
                    maxWidth='calc(100% - 32px)'
                    style={{ overflow: 'hidden' }}
                  >
                    <ModelSelect
                      dependencyRootNodes$={dependencyRootNodes$}
                      currentEntity$={currentUserClassifier$}
                      fetchDependencyModels={fetchDependencyModels}
                      searchedBimNode$={searchedBimNode$}
                      modelStoreService={modelStoreService}
                      getViewerId={(node, viewerRef) =>
                        getViewerId({
                          node,
                          viewerRef,
                          nodes: classifierService.nodes$.getState(),
                          getNodeFunc: getNode,
                          validateModelTypes,
                        })
                      }
                    />
                  </Box>
                }
                foldedTitle='Просмотр модели'
                onOpened={() => {
                  goals.expandViewer()
                  logEventEditor('EXPAND_VIEWER', { entity: 'Estimation' })
                }}
                onClosed={() => {
                  goals.foldViewer()
                  logEventEditor('FOLD_VIEWER', { entity: 'Estimation' })
                }}
                data-test-id='ModelViewerSection'
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyVH
              >
                <forgeViewer.ViewerComponent
                  selectedModels={selectedModels}
                  resetSearchNode={searchModel.resetSearchNode}
                  selectViewerElements={selectViewerRefs}
                  resetSelectedModels={selectedModelsService.reset}
                  loadModelByUrl={loadModelByUrl}
                  getModelUrl={getViewerModelUrl}
                  fetchToken={fetchToken}
                />
              </ResizablePanel>

              <ResizablePanel
                title='Зависимости'
                header={
                  <SeparatorList>
                    <Tooltip placement='top' title='Добавить модель(и)'>
                      <IconButton
                        data-test-id='openDependencyList'
                        data-test-ui-type='CreateButton'
                        disabled={readonlyMode.enabled}
                        onClick={openExplorer}
                      >
                        <PlusCircle color='rgba(53, 59, 96, 0.5)' />
                      </IconButton>
                    </Tooltip>

                    {InclusionFilterButton}
                  </SeparatorList>
                }
                headerSecondary={<ModelManageMenu />}
                footer={<DependenciesStatusPanel />}
                onOpened={() => {
                  goals.expandDependencies()
                }}
                onClosed={() => {
                  goals.foldDependencies()
                }}
                data-test-id='DependenciesSection'
                body={InclusionFilterContent}
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyDN
              >
                <Box
                  width='100%'
                  height='100%'
                  display='flex'
                  flexDirection='column'
                >
                  <TreeLoader
                    loading={fetchFlatListDependenciesPending}
                    text='Идет загрузка...'
                  />

                  <Cover
                    open={
                      currentUserClassifier?.sourceClassifiers?.length === 0 &&
                      !fetchFlatListDependenciesPending
                    }
                    icon={
                      <Substrate>
                        <WithCursorIcon>
                          <PlusCircle color='#DADADA' width={45} height={45} />
                        </WithCursorIcon>
                      </Substrate>
                    }
                    title={
                      <>
                        Добавьте Модели в “Зависимости”
                        <br /> для начала работы
                      </>
                    }
                  />

                  <DependencyTreeWrap
                    dynamicGroupsConditions={dynamicGroupConditions}
                    currentUserClassifier$={currentUserClassifier$}
                    dependencyCheckedModel={dependencyCheckedModel}
                    dependencyExpandModel={dependencyExpandModel}
                    dependencyTreeModel={dependencyTreeModel}
                    fetchAllInclusion={fetchAllInclusion}
                    filteredFlatTree$={filteredFlatTree$}
                    loadOnceNodeInclusion={loadOnceNodeInclusion}
                    nodes$={classifierService.nodes$}
                    searchModel={searchModel}
                    inclusionStatusWithTranscript={
                      inclusionStatusWithTranscript
                    }
                    inclusionStore$={inclusionStore$}
                    sourceClassifiersLoaded$={sourceClassifiersLoaded$}
                    searchSourceData$={searchSourceModel.searchSourceData$}
                    selectForgeRefs={setSelectViewerRefs}
                  />
                  <SelectDependencies
                    ClassifiersTree={ClassifiersTree}
                    ModelsTree={ModelsTree}
                    brand={projectBadge}
                  />
                </Box>
              </ResizablePanel>

              <ResizablePanel
                title='Мои расчёты'
                header={
                  <Tooltip placement='top' title='Добавить папку в Мои расчёты'>
                    <IconButton
                      onClick={() => createRootGroup()}
                      data-test-id='addRootGroup'
                      data-test-ui-type='CreateButton'
                      disabled={readonlyMode.enabled}
                    >
                      <FolderWithPlus color='rgba(53, 59, 96, 0.5)' />
                    </IconButton>
                  </Tooltip>
                }
                onOpened={() => {
                  goals.expandClassifier()
                }}
                onClosed={() => {
                  goals.foldClassifier()
                }}
                data-test-id='ElementsSection'
                footer={
                  <EditorTreeStatusPanel
                    content={
                      <RunEstimation
                        widthTreeResizableCol={widthTreeResizableCol}
                        disabled={
                          dynamicGroupPending ||
                          estimationStatus === 'Finished' ||
                          groupingProcessStatus === 'InProgress' ||
                          groupingProcessStatus === 'ErrorTimedOut'
                        }
                        withoutDownload
                        delayedRun={conditionChanged}
                        color='primary'
                      />
                    }
                  />
                }
                headerSecondary={
                  <>
                    <Tooltip
                      placement='top'
                      title={
                        //TODO Tooltip
                        dynamicGroupMode ? (
                          <DynamicTooltipWrapper>
                            <DynamicTooltipHeader>
                              Показать исходную иерархию
                            </DynamicTooltipHeader>
                            <DynamicTooltipBody>
                              Отключив режим отображения группировок вы
                              <br />
                              сможете настраивать структуру папок
                            </DynamicTooltipBody>
                          </DynamicTooltipWrapper>
                        ) : (
                          <DynamicTooltipWrapper>
                            <DynamicTooltipHeader>
                              Показать иерархию с группировками.
                            </DynamicTooltipHeader>
                            <DynamicTooltipBody>
                              Данный режим отображает финальную
                              <br /> структуру папок участвующих в расчетах
                            </DynamicTooltipBody>
                          </DynamicTooltipWrapper>
                        )
                      }
                    >
                      <DynamicModeWrapper
                        onClick={onToggleDynamicMode}
                        data-test-ui-type='changeDynamicGroupMode'
                      >
                        {dynamicGroupMode && (
                          <DynamicModeText>
                            Вкл. режим группировок
                          </DynamicModeText>
                        )}
                        <DynamicModeIconButton selected={dynamicGroupMode}>
                          <LayersWithoutSubstrate />
                        </DynamicModeIconButton>
                      </DynamicModeWrapper>
                    </Tooltip>
                  </>
                }
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyDN
              >
                <TreeLoader
                  loading={fetchFlatListItemsPending}
                  text='Идет загрузка...'
                />

                <Cover
                  open={
                    currentUserClassifier?.children.length === 0 &&
                    !fetchFlatListItemsPending &&
                    !inCreateNode &&
                    !dynamicGroupPending &&
                    groupingProcessStatus !== 'InProgress'
                  }
                  icon={
                    <Substrate>
                      <WithCursorIcon>
                        <FolderWithPlus
                          color='#DADADA'
                          width={45}
                          height={45}
                        />
                      </WithCursorIcon>
                    </Substrate>
                  }
                  title={
                    <>
                      Создайте желаемую структуру <br /> папок и перенесите в
                      них необходимые <br />
                      элементы из зависимостей
                    </>
                  }
                />

                <EditorTreeWrap
                  openedResultCalculating={openedResultCalculating}
                  searchModel={searchModel}
                  selectViewerRefs={setSelectViewerRefs}
                  currentUserClassifier={currentUserClassifier}
                  dependenciesWithModels$={
                    modelStoreService.dependenciesWithModels$
                  }
                />
              </ResizablePanel>

              <ResizablePanel
                foldedTitle='Редактор формул'
                noHeaderSeparator
                data-test-id='FormulaSection'
                onOpened={() => {
                  goals.expandGroupSettings()
                }}
                onClosed={() => {
                  goals.foldGroupSettings()
                }}
                header={
                  //TODO - Сделать переиспользуемый компонент с кодом ниже
                  <EditorPanelHeader>
                    <EditorPanelHeaderIcon>
                      <CountIconWrap>
                        <CountBig />
                      </CountIconWrap>
                    </EditorPanelHeaderIcon>
                    <EditorPanelHeaderTitle data-test-ui-type='Text'>
                      {(widthColMoreMin || !selectedGroup?.name) &&
                        'Редактор формул'}
                      {widthColMoreMin && selectedGroup?.name && `: `}
                      <Tooltip title={selectedGroup?.name || ''}>
                        <> {truncateText(selectedGroup?.name, 25)}</>
                      </Tooltip>
                    </EditorPanelHeaderTitle>
                  </EditorPanelHeader>
                }
                bodyStyles={{ borderRight: '' }}
              >
                <Cover
                  open={
                    !selectedGroup &&
                    currentUserClassifier?.children.length !== 0
                  }
                  icon={
                    <Substrate p='19px 16px 20px 16px'>
                      <FolderWithoutSubstrate width={40} height={33} />
                    </Substrate>
                  }
                  title={<>Ни одна папка в третьей колонке не выбрана</>}
                  pb='46px'
                />
                <GroupSettings
                  setOpenedResultCalculating={setOpenedResultCalculating}
                  widthFormulaResizableCol={widthFormulaResizableCol}
                  nodes$={classifierService.nodes$}
                  classifierId={currentUserClassifier.id}
                />
              </ResizablePanel>

              <ResizablePanel
                foldedTitle='Результат расчёта'
                noHeaderSeparator
                data-test-id='ResultSection'
                headerSecondary={<ReportSubtotals />}
                header={
                  <EditorPanelHeader>
                    <EditorPanelHeaderIcon>
                      <CountIconWrap>
                        <CheckListIcon />
                      </CountIconWrap>
                    </EditorPanelHeaderIcon>
                    <EditorPanelHeaderTitle>
                      Результат расчёта
                    </EditorPanelHeaderTitle>
                  </EditorPanelHeader>
                }
                bodyStyles={{ borderRight: '' }}
              >
                <EstimationReport
                  action={
                    <RunEstimation
                      color='secondary'
                      delayedRun={conditionChanged}
                      size='regular'
                      disabled={
                        dynamicGroupPending ||
                        groupingProcessStatus === 'InProgress' ||
                        groupingProcessStatus === 'ErrorTimedOut'
                      }
                    />
                  }
                  nodes$={classifierService.nodes$}
                  notActualResult={
                    <NotActual
                      actionButton={
                        <RunEstimation
                          delayedRun={conditionChanged}
                          size='regular'
                          disabled={
                            dynamicGroupPending ||
                            groupingProcessStatus === 'InProgress' ||
                            groupingProcessStatus === 'ErrorTimedOut'
                          }
                        />
                      }
                      notActualResult={notActualResult}
                    />
                  }
                  showFooter={!notActualResult}
                  notStarted={!statusPending && estimationStatus !== 'Finished'}
                  pending={
                    estimationStatus === 'Started' ||
                    estimationStatus === 'InProgress' ||
                    reportPending
                  }
                  previewResult$={previewResult$}
                  containerMounted={containerMounted}
                  parentData$={parentData$}
                  searchModel={searchModel}
                  selectViewerRefs={setSelectViewerRefs}
                />
              </ResizablePanel>
            </Resizable>
          </PageContentContainer>
          <VersionHistory
            open={openVersionHistory}
            onClose={onCloseVersionHistory}
            onRedirectToLast={() => {
              history.push(`/estimation/${currentEstimation.id}`)
            }}
            isCurrentVersion={isCurrentVersion}
            beforeList={NamedVersionsSwitch}
            versionList={
              <VersionList
                onMenuClick={setCtxMenu}
                currentVersion={currentEstimation.version}
                dates={versionDates}
                versions={versions}
                fetchVersions={async versionDate => {
                  await api.Estimation.fetchVersionByDate.defaultContext({
                    estimationId: currentEstimation.id,
                    versionDate,
                  })
                }}
                anotherListOpened={namedVersions}
                contextMenu={!changeNameDialog && ContextMenu()}
                openVersionHistory={openVersionHistory}
                expandModel={expandVersionModel}
              />
            }
          />
          {ChangeVersionNameDialog()}
        </Box>
      </>
    )
  },
)

EstimationEditorPage.displayName = 'EstimationEditorPage'
