import {
  createEvent,
  createStore,
  guard,
  sample,
  Store,
  Event,
  restore,
  combine,
} from 'effector'

import { EstimationReport } from '@gmini/sm-api-sdk/lib/EstimationApi/EstimationRepo/EstimationReport'

import { Estimation } from '@gmini/sm-api-sdk/lib/EstimationApi/EstimationRepo/Estimation'

export function createReportService<T extends { id: number; version: number }>({
  entity$,
  status$,
  fetchTrigger,
  reset,
}: {
  entity$: Store<T | null>
  status$: Store<Estimation.Status | null>
  fetchTrigger: Event<any>
  reset: Event<any>
}) {
  const fetchReportResult = createEvent<void>()
  const containerMounted = createEvent<boolean>()
  const containerMounted$ = restore(containerMounted, false)

  const previewResult$ = createStore<EstimationReport.ReportResult | null>(null)
    .on(
      EstimationReport.ReportResult.fetch.defaultContext.doneData,
      (_, data) => data,
    )
    .reset(reset)

  guard({
    clock: containerMounted.filter({ fn: mounted => mounted }),
    source: combine({ previewResult: previewResult$, status: status$ }),
    filter: ({ previewResult, status }) =>
      status === 'Finished' && !previewResult,
    target: fetchReportResult,
  })

  guard({
    clock: fetchTrigger,
    filter: containerMounted$,
    target: fetchReportResult,
  })

  sample({
    clock: fetchReportResult,
    source: entity$,
    fn: entity => (entity ? entity : null),
  }).watch(entity => {
    if (entity) {
      EstimationReport.ReportResult.fetch.defaultContext.submit({
        estimationId: entity.id,
        estimationVersion: entity.version,
      })
    }
  })

  return {
    previewResult$,
    containerMounted,
    reportPending$: EstimationReport.ReportResult.fetch.defaultContext.pending$,
    resetPreviewResult: reset,
  }
}
