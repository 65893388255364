import * as React from 'react'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import MultiBackend from 'react-dnd-multi-backend'
import { DndProvider } from 'react-dnd'
import { root } from 'effector-root'
import { createInspector } from 'effector-logger/inspector'
import { attachLogger } from 'effector-logger/attach'

import { ReactKeycloakProvider } from '@react-keycloak/web'
import { AuthClientTokens } from '@react-keycloak/core/lib/types'

import {
  DefaultSnackbarProvider,
  muiTheme,
  OfflineModal,
  PageTitleOptions,
} from '@gmini/ui-kit'

import { Page404 } from '@gmini/ui-kit/lib/Page404'

import { MetrikaProvider } from '@gmini/common/lib/metrika/provider'
import { AuthContent } from '@gmini/common/lib/keycloakAuth/AuthContent'
import { saveAuthData } from '@gmini/common/lib/keycloakAuth/auth'

import { ApiCallService } from '@gmini/common/lib/Services/EstimationService/ApiService'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import * as config from '../../config'

import { VolumesExplorerPage } from '../EstimationExplorerPage'
import { Notifications } from '../Notifications'
import { CustomHTML5toTouch } from '../dnd'
import { EstimationEditorPageWrap } from '../EstimationEditor/EstimationEditorPageWrap'
import { EditorByVersionWrap } from '../EstimationEditor/EditorByVersionWrap'
import { initAmplitude } from '../../config/amplitude'

import { notificationService } from '../../services/notificationService'

if (config.logEffector) {
  attachLogger(root)
  createInspector()
}

initAmplitude()

export const App = (): JSX.Element => {
  const onTokens = React.useCallback(
    ({ idToken, refreshToken, token }: AuthClientTokens) => {
      if (!token || !refreshToken || !idToken) {
        // Если приходят пустые токены, то значит юзер разлогинился или залогинился под другим юзернеймом на другой вкладке
        // и кейклок сам сделает редирект на страницу авторизации что бы обновить куки
        return
      }

      saveAuthData({
        accessToken: token,
        refreshToken,
        idToken,
      })
    },
    [],
  )
  return (
    <ReactKeycloakProvider
      authClient={config.keycloakClient}
      onTokens={onTokens}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
      }}
    >
      <MetrikaProvider>
        <BrowserRouter>
          <ThemeProvider theme={muiTheme}>
            <DefaultSnackbarProvider>
              <PageTitleOptions siteName='Объёмы' />

              <CssBaseline />
              <Helmet>
                <link
                  rel='stylesheet'
                  href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
                />
              </Helmet>

              <DndProvider
                backend={MultiBackend as any}
                options={CustomHTML5toTouch}
              >
                <Notifications />
                <OfflineModal />
                <AuthContent appName='Объёмы'>
                  <Switch>
                    <Route
                      path='/estimation/:id'
                      exact
                      component={EstimationEditorPageWrap}
                    />
                    <Route
                      path='/estimation/:id/version/:version'
                      exact
                      component={EditorByVersionWrap}
                    />
                    <Route
                      path='/sign-in'
                      exact
                      render={() => <Redirect to='/' />}
                    />
                    <Route path='/' exact component={VolumesExplorerPage} />
                    <Route component={Page404} />
                  </Switch>
                </AuthContent>
              </DndProvider>
            </DefaultSnackbarProvider>
          </ThemeProvider>
        </BrowserRouter>
      </MetrikaProvider>
    </ReactKeycloakProvider>
  )
}

App.displayName = 'App'

ApiCallService.fail.watch(({ name, params, error }) => {
  if (error.status === 'fail' || (error as any).name === 'AbortError') {
    return
  }

  api.logApiError(error)
})

notificationService.validationError.watch(params => {
  params.errors.forEach(error => {
    console.error(`WebSocket error:\n ${JSON.stringify(error, null, 2)}`)
  })
})
