import { Store, Event } from 'effector'
import { useStore } from 'effector-react'
import React, { useEffect } from 'react'

import { SearchModel } from '@gmini/common'

import { Nodes } from '@gmini/common/lib/classifier-service'

import { EstimationReport as EstimationReportApi } from '@gmini/sm-api-sdk/lib/EstimationApi/EstimationRepo/EstimationReport'

import { RenderTableContent } from './Table/TableContent'

import { Pending } from './Report/Pending'

import { Refresh } from './Report/Refresh'
import {
  EstimationReportWrap,
  StatusPanelLayout,
} from './EstimationReport.styled'
import { SelectReport } from './SelectReport/SelectReport'

type EstimationReportProps = {
  action: React.ReactNode
  pending: boolean
  previewResult$: Store<EstimationReportApi.ReportResult | null>
  containerMounted: Event<boolean>
  notStarted: boolean
  notActualResult: React.ReactNode
  parentData$: Store<{ id: number; name: string }[]>
  searchModel: SearchModel
  showFooter: boolean
  selectViewerRefs: (value: Record<string, string[]>) => void
  nodes$: Store<Nodes>
}

export const EstimationReport = ({
  action,
  notActualResult,
  pending,
  previewResult$,
  containerMounted,
  notStarted,
  parentData$,
  searchModel,
  showFooter,
  selectViewerRefs,
  nodes$,
}: EstimationReportProps) => {
  const previewResult = useStore(previewResult$)
  const [currentReport, setCurrentReport] = React.useState<
    EstimationReportApi.TreeGroupItem | EstimationReportApi.TreeBimItem
  >()

  useEffect(() => {
    containerMounted(true)

    return () => {
      containerMounted(false)
    }
  }, [containerMounted])

  if (pending) {
    return <Pending text='Идет расчет результатов...' />
  }

  if (!previewResult && (notActualResult || notStarted)) {
    return <Refresh action={action} />
  }

  if (!previewResult) {
    return null
  }

  const rootNodes = previewResult.tree
    .filter(node => Number(node.parentExternalId) === 0)
    .sort((a, b) => (a.name < b.name ? -1 : 1))

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '17px',
        height: '100%',
      }}
    >
      {notActualResult}
      <EstimationReportWrap>
        <SelectReport
          rootReportNodes={rootNodes}
          onReportChange={report => {
            setCurrentReport(report)
          }}
        />
        {currentReport && (
          <RenderTableContent
            columns={previewResult.columns}
            rootNode={currentReport}
            tree={previewResult.tree}
            rowHeight={30}
            columnHeight={36}
            parentData$={parentData$}
            searchModel={searchModel}
            selectViewerRefs={selectViewerRefs}
            nodes$={nodes$}
          />
        )}

        <div
          style={{
            height: '56px',
          }}
        />
      </EstimationReportWrap>

      {showFooter && <StatusPanelLayout>{action}</StatusPanelLayout>}
    </div>
  )
}
