import { combine, createDomain, createEvent, createStore } from 'effector'

import { Item } from '@gmini/common/lib/Explorer'

import * as smApi from '@gmini/sm-api-sdk'

import { isNotEmpty } from '@gmini/utils'

import * as n from '@gmini/common/lib/Services/EstimationService/Node'
import { getChildren } from '@gmini/common/lib/Services/EstimationService'

import { estimationService } from '../../services/estimationService'

const domain = createDomain()

export const reset = domain.event('reset')

export const setSelectedProject = createEvent<smApi.Project>()

export const selectedProject$ = createStore<smApi.Project | null>(null).on(
  setSelectedProject,
  (_state, result) => result,
)

const repo$ = combine(
  { nodes: estimationService.nodes$, selectedProject: selectedProject$ },
  ({ nodes, selectedProject }) => {
    if (!selectedProject) {
      return null
    }

    const key = n.EstimationRepoNode.getKey({ id: selectedProject.urn })
    return n.EstimationRepoNode.getByKey(nodes, key)
  },
)
const root$ = combine(
  {
    nodes: estimationService.nodes$,
    relations: estimationService.relations$,
    repo: repo$,
  },
  ({ nodes, relations, repo }) =>
    repo ? getChildren(nodes, relations, repo) : [],
)

export const tree$ = combine(
  {
    root: root$,
    nodes: estimationService.nodes$,
    relations: estimationService.relations$,
  },
  ({ root, nodes, relations }): readonly Item[] => {
    function createNode({
      node,
      path,
    }: {
      node: {
        id: number
        type: n.NodeType
      }
      path: number
    }): null | Item {
      const recursive =
        ({ parentPath }: { parentPath: number }) =>
        ({ id, type }: { id: number; type: n.NodeType }): null | Item => {
          const node = n.Node.getByRef(nodes, { id, type })
          const nestingLevel = parentPath + 1

          if (!node) {
            return null
          }

          switch (node.type) {
            case 'EstimationRepoFolderNode': {
              return {
                type: 'Category',
                id,
                title: node.name,
                total: node.total,
                children: getChildren(nodes, relations, node)
                  .map(node => recursive({ parentPath: nestingLevel })(node))
                  .filter(isNotEmpty),
                nestingLevel,
                projectUrn: node.projectUrn,
              }
            }

            case 'EstimationNode':
              return {
                type: 'Entity',
                id,
                title: node.name,
                version: node.version,
                nestingLevel,
                readOnly: node.readOnly,
                owner: node.owner,
                projectUrn: node.projectUrn,
              }

            default:
              return null
          }
        }
      return recursive({ parentPath: path })(node)
    }

    return root.map(node => createNode({ node, path: 1 })).filter(isNotEmpty)
  },
)
