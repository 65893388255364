import React from 'react'
import { combine, Store } from 'effector'
import { useStore, useStoreMap } from 'effector-react'

import { Badge, Tabs, WarningCycle, TabsRenderBadgeProps } from '@gmini/ui-kit'

import * as smApi from '@gmini/sm-api-sdk'

import { currentGroup$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/groupModel'

import './conditions/saveConditions.init'

import { dynamicGroupMode$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/dynamicGroupMode'

import { Nodes } from '@gmini/common/lib/classifier-service'

import { useSelectedGroupNode } from '../CurrentCalculation'

import { setComponentMounted } from '../EstimationEditor/model/current/fetchConditions.init'

import {
  getSourceGroupId,
  useDisabledGroup,
} from '../EstimationEditor/model/group-tree'

import { useCurrentCalculation } from '../CurrentCalculation/useCurrentCalculation'

import { useClassifierDynamicConditions } from '../EstimationEditor/model/dynamic-conditions.store'

import { GroupingsContainer } from './GroupingsContainer'
import { RuleEditor } from './RuleEditor'
import {
  Container,
  InfoMessage,
  InfoMessageContainer,
} from './GroupSettings.styled'
import { selectedPropertiesById$, unsavedConditionsByRule$ } from './conditions'

import { useNoAvailableMessage } from './useNoAvailableMessage'

export enum TabIndex {
  Calculations,
  Grouping,
}

const editorDisabled$ = combine(
  [
    smApi.UserClassifierGroup.createGroupReference.defaultContext.pending$,
    smApi.UserClassifierGroup.create.defaultContext.pending$,
    smApi.UserClassifierGroup.move.defaultContext.pending$,
    smApi.UserClassifierGroup.rename.defaultContext.pending$,
    smApi.UserClassifierGroup.remove.defaultContext.pending$,
    smApi.BimReference.create.defaultContext.pending$,
    smApi.BimReference.move.defaultContext.pending$,
    smApi.BimReference.remove.defaultContext.pending$,
  ],
  pendings => pendings.some(Boolean),
)

const groupingDisabled$ = combine(
  [
    smApi.UserClassifier.removeAll.defaultContext.pending$,
    smApi.UserClassifier.removeDependency.defaultContext.pending$,
    smApi.UserClassifierGroup.Property.List.fetchElementProperties
      .defaultContext.pending$,
    smApi.UserClassifierGroup.Property.List.fetchStandardSizeProperties
      .defaultContext.pending$,
    smApi.UserClassifierTree.DynamicGroupItem.getList.defaultContext.pending$,
  ],
  pendings => pendings.some(Boolean),
)

type GroupSettingsProps = {
  setOpenedResultCalculating: React.Dispatch<React.SetStateAction<boolean>>
  nodes$: Store<Nodes>
  widthFormulaResizableCol: number
  classifierId: number
}
export const GroupSettings = React.memo(
  ({
    setOpenedResultCalculating,
    nodes$,
    widthFormulaResizableCol,
    classifierId,
  }: GroupSettingsProps) => {
    const [tabIndex, setTabIndex] = React.useState(TabIndex.Calculations)
    const handleChange = (newTabIndex: number) => {
      setTabIndex(newTabIndex)
    }
    const currentCalculation = useCurrentCalculation()
    const calculationId = currentCalculation?.id
    const editorDisabled = useStore(editorDisabled$)
    const currentGroupPath = useStore(currentGroup$)

    const dynamicGroupMode = useStore(dynamicGroupMode$)
    const groupingDisabled = useStore(groupingDisabled$)

    const selectedGroup = useSelectedGroupNode({ nodes$ })

    const dynamicGroupConditions = useClassifierDynamicConditions({
      classifierId,
    })

    const selectedPropertiesIds = useStoreMap({
      store: selectedPropertiesById$,
      keys: [selectedGroup?.id],
      fn: (selectedPropertiesById, [id]) =>
        id && id in selectedPropertiesById ? selectedPropertiesById[id] : [],
    })
    const difficultConditions = useStoreMap({
      store: unsavedConditionsByRule$,
      keys: [calculationId],
      fn: (calculations, [calculationId]) =>
        calculationId && calculations[calculationId]
          ? calculations[calculationId]
          : [],
    })

    const countConditions =
      selectedPropertiesIds.length + (difficultConditions?.length || 0)

    const isDisabledGroup = useDisabledGroup()(selectedGroup?.id || 0)

    const getGroupingConditions = React.useCallback(
      (groupId: number) =>
        dynamicGroupConditions.find(
          ({ sourceGroupId }) => sourceGroupId === groupId,
        )?.groupingConditions || [],
      [dynamicGroupConditions],
    )

    React.useEffect(() => {
      setComponentMounted(true)
      return () => {
        setComponentMounted(false)
      }
    }, [])

    const noAvailableMessage = useNoAvailableMessage(
      selectedGroup,
      nodes$,
      classifierId,
    )

    if (!selectedGroup) {
      return null
    }

    const groupId = getSourceGroupId(selectedGroup)
    const groupingConditions = getGroupingConditions(groupId)

    if (noAvailableMessage) {
      return noAvailableMessage
    }

    const renderBadgeCondition = ({ hoverTab }: TabsRenderBadgeProps) =>
      countConditions > 0 ? (
        <Badge
          bgColor={
            tabIndex === TabIndex.Calculations || hoverTab
              ? '#4C5ECF'
              : '#A2A3B7'
          }
        >
          {countConditions}
        </Badge>
      ) : null

    const renderBadgeGrouping = ({ hoverTab }: TabsRenderBadgeProps) =>
      Number(groupingConditions?.length) > 0 ? (
        <Badge
          bgColor={
            tabIndex === TabIndex.Grouping || hoverTab ? '#4C5ECF' : '#A2A3B7'
          }
        >
          {groupingConditions?.length}
        </Badge>
      ) : null

    return (
      <Container>
        <Tabs
          onChangeTab={handleChange}
          activeTabIndex={tabIndex}
          headerStyles={{ fontSize: '14px', marginRight: '24px' }}
          contentStyles={{ height: '100%', padding: '0', flex: 1 }}
          tabs={[
            {
              title: 'Условия',
              content: (
                <>
                  {dynamicGroupMode && !isDisabledGroup && (
                    <InfoMessageContainer>
                      <WarningCycle color='rgba(53, 59, 96, 0.5)' />
                      <InfoMessage>
                        Вы находитесь в режиме отображения группировок, в данном
                        режиме вы не можете редактировать условия. Если вы
                        хотите отредактировать условия, необходимо отключить
                        режим отображения группировок.
                      </InfoMessage>
                    </InfoMessageContainer>
                  )}
                  <RuleEditor
                    currentGroup={selectedGroup}
                    widthFormulaResizableCol={widthFormulaResizableCol}
                    disabled={editorDisabled || dynamicGroupMode}
                  />
                </>
              ),
              renderBadge: renderBadgeCondition,
            },
            {
              title: 'Группировки',
              content: (
                <>
                  {dynamicGroupMode && (
                    <InfoMessageContainer>
                      <WarningCycle color='rgba(53, 59, 96, 0.5)' />
                      <InfoMessage>
                        Вы находитесь в режиме отображения группировок, в данном
                        режиме вы не можете редактировать параметры группировки.
                        Если вы хотите отредактировать параметры группировки,
                        необходимо отключить режим отображения группировок.
                      </InfoMessage>
                    </InfoMessageContainer>
                  )}
                  <GroupingsContainer
                    pending={groupingDisabled}
                    currentSelectedGroup={selectedGroup}
                    currentGroupPath={currentGroupPath}
                    nodes$={nodes$}
                    widthFormulaResizableCol={widthFormulaResizableCol}
                  />
                </>
              ),
              renderBadge: renderBadgeGrouping,
            },
          ]}
        />
      </Container>
    )
  },
)

GroupSettings.displayName = 'GroupSettings'
